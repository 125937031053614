import React from 'react';
import logo from './wcnagpur-logo.png';

const Header = () => (
  <header className="bg-white shadow-md py-4 sm:py-6">
    <div className="container mx-auto flex justify-center px-4 sm:px-6">
      <img src={logo}  alt="WordCamp Nagpur Logo" />
    </div>
  </header>
);

export default Header;
